body,
h1,
h2,
h3,
p,
ul {
  margin: 0 !important;
  padding: 0;
}

body {
  font-family: "DniproCity", sans-serif !important;
  width: 100% !important;
  background-color: #ecf0f2 !important;
}