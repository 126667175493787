@media (max-width: 575.98px) {
  .iteminfo__image {
    max-width: 100%!important;
    width: 100% !important;
    min-height: 300px !important;
    max-height: 300px !important;
  }
}

@media (min-width: 575.98px) {
  .iteminfo__image {
    max-width: 100%!important;
    width: 100% !important;
    min-height: 400px !important;
    max-height: 400px !important;
  }
}

@media (min-width: 768px) {
  .iteminfo__image {
    max-width: 400px!important;
    width: 400px !important;
    min-height: 460px !important;
    max-height: 460px !important;
  }
}

@media (min-width: 992px) {
  .iteminfo__image {
    max-width: 400px !important;
    width: 400px !important;
    min-height: 460px !important;
    max-height: 460px !important;
  }

}

@media (min-width: 1200px) {
  .iteminfo__image {
    max-width: 450px !important;
    width: 450px !important;
    min-height: 510px !important;
    max-height: 510px !important;
  }

}

.iteminfo__image {
  width: 500px;
  max-width: 500px ;
  min-height: 560px;
  max-height: 560px;
  border-radius: 25px;
  object-fit: cover;
}

.expansion__button {
  text-decoration-line: underline;
  text-decoration-thickness: 1.5px;
  border: none;
  padding-top: .6rem;
  color: grey;
  cursor: pointer;
}