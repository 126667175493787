.layout__badge {
  width: fit-content;
  padding: 0.02rem 1rem;
  border-radius: 10px;
  color: #434242;
}

.badge-bgcolor0 { 
  background-color: #E8DADC;
}

.badge-bgcolor1 { 
  background-color: #E8DAE8;
}

.badge-bgcolor2 { 
  background-color: #DFDAE8;
}

.badge-bgcolor3 { 
  background-color: #DAE5E8;
}