.login__form {
  width: 100% !important;
  max-width: 300px;
}

.login__button-color {
  background-color: #33C3FF;
  transition: .1s background-color linear;
}

.login__button-color:hover {
  background-color: rgb(45, 168, 221);
}

.login__form-input {
  box-sizing: border-box;
  border: 1px solid #686868;
  border-radius: 14px;
  background-color: white;
}

.login__form-input:focus {
  border: 1px solid #686868;
  outline: none;
}