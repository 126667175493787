.lh-half {
  line-height: 0.8 !important;
}

.icon__link {
  cursor: pointer;  
}

.color-dark {
  color: #2a2a2a;
}

.fs-small {
  font-size: 1.1rem !important;
}

.input-styled {
  border: 1px grey solid;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-styled:focus {
  box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, 0.411);
  border: 1px rgba(107, 107, 107, 0.349) solid;
  outline: none;
}