@media (max-width: 768px) {
  .footer__img-size {
    width: 143px !important;
    height: 38px !important;
  }
}

.footer__container-height {
  min-height: 100px;
}

.footer__img-size {
  width: 169px;
  height: 49px;
}