@media (max-width: 768px) {
  .form__button {
    width: 250px !important;
  }

  .form__title-text {
    font-size: 18px !important;
  }

}

@media (max-width: 575.98px) {
  .form__button {
    width: 210px !important;
  }

}

.form__title-text {
  font-size: 20px;
}

.form__button {
  width: 300px;
}

.form-width {
  width: 100% !important;
  max-width: 700px;
}

.register__form-input {
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  border: 1px solid #686868;
  border-radius: 14px;
  background-color: white;
}

.register__form-input:focus {
  border: 1px solid #686868;
  outline: none;
}

.field {
  width: 100%;
  height: 72px;
  max-width: 300px;
}