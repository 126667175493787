.subform__container-height {
  min-height: 140px;
}

.subform__input-styling {
  border: 2px solid #686868;
  outline: none;
  transition: .2s ease-in box-shadow;
}

.subform__button-styling {
  font-size: .8rem !important;
  padding: .7rem 1.2rem !important;
}

.subform__input-styling:focus {
  border: 2px solid rgba(7, 86, 177, 0.664);
  box-shadow: 0 0 0 0.10rem rgba(7, 86, 177, 0.664);
}

.subform__icon-size {
  max-height: 100px;
  max-width: 100px;
}