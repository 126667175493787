.button__purchase-red {
  background-color: #e53c3c;
  height: 45px;
  padding: 0.6rem 1.5rem;
  border-radius: 14px;
  border: none;
  color: white;
  transition: 0.1s background-color ease-out;
}

.button__purchase-red:hover {
  background-color: #be3131;
}