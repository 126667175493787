.slider__info {
  width: 18.75rem ;
  height: 18.75rem ;
  background: rgba(255, 255, 255, 0.473);
  border-radius: 27px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  position: absolute;
  top: 8%;
  left: 12%;
}

.slider__text-truncate {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}