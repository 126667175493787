@media (max-width: 575.98px) {
  .navbar__logo {
    width: 120px !important;
  }
}

@media (max-width: 768px) {
  .navbar__logo {
    width: 150px !important;
    height: 50px !important;
  }
}

@media (max-width: 991px) {
  .navbar__logo {
    width: 250px !important;
    height: 50px !important;
  }
}

.navbar__logo {
  width: 300px;
}