.uploader__container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-left: .8rem;
  max-width: 70% !important;
  padding: 5px;
}

.uploader__dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed grey;
  border-radius: 10px;
  width: 100%;
  min-height: 78px;
}

.image__uploader-img {
  max-width: 100%;
  max-height: 400px;
  object-fit: fill;
}

