@media (max-width: 575.98px) {
  .event__item-image {
    max-width: 100% !important;
    width: 100% !important;
    min-height: 200px !important;
    max-height: 200px !important;
    border-radius: 0px;
  }

}

@media (max-width: 768px) {
  .event__item-image {
    max-width: 100% !important;
    width: 100% !important;
    min-height: 200px !important;
    max-height: 200px !important;
    border-radius: 0px;
  }

}

.event__item-timeline {
  font-size: 90px;
}

.event__item-image {
  width: 230px;
  max-width: 240px;
  min-height: 320px;
  max-height: 320px;
  object-fit: cover;
}

.event__item-desc {
  word-break: break-all;
  min-height: 30px;
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.event__card-wrapper {
  color: #2a2a2a;
  transition: .3s ease-in-out;
}

.event__card-wrapper:hover {
  background-color: rgb(249, 249, 249);
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.2);
}

.block__buybutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.block__buybutton-heart {
  border: none;
  background-color: inherit;
  color: #2a2a2a;
  transition: color 0.2s ease;
}

.block__buybutton-heart:hover {
  color: #E53C3C;
}