  /* Large screen */

  @media (min-width: 1200px) {

    .headlogo_text-big {
      font-size: 62px;
    }

    .headlogo_text-small {
      font-size: 17px;
    }

    .headlogo-container-img {
      width: 80px;
    }
  }

  /* Medium screen */

  @media (max-width: 1200px) {

    .headlogo_text-big {
      font-size: 50px;
    }

    .headlogo_text-small {
      font-size: 14px;
    }

    .headlogo-container-img {
      width: 75px;
    }
  }

  /* Small screen */

  @media (max-width: 768px) {

    .headlogo-container-img {
      width: 70px;
    }
  }

  .headlogo-container {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .headlogo-container-img {
    margin-right: 25px;
  }

  .headlogo-icon {
    width: 100%;
    object-fit: cover;
  }

  .headlogo_container-text {
    width: auto;
  }

  .headlogo_text-big {
    font-weight: 500;
    line-height: 50px;
  }