@media (max-width: 768px) {
  .swiper {
    width: 100%;
    height: 400px !important;
  }

  .swiper-button-next::after {
    margin-left: 1.5rem;
  }

  .swiper-button-prev::after {
    margin-right: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .swiper {
    width: 100%;
    height: 300px !important;
  }
}

.swiper {
  width: 100%;
  height: 600px;
  border-radius: 25px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.swiper-button-next {
  text-align: center;
  margin-right: 1rem;
}

.swiper-button-prev {
  text-align: center;
  margin-left: 1rem;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  border-radius: 50%;
  background-color: #fff;
  font-size: 1.7rem;
  color: black;
  transition: background-color 0.5s ease;
}

.swiper-button-next::after {
  padding: 6px 11px 6px 14px;
}

.swiper-button-prev::after {
  padding: 6px 14px 6px 11px;
}

.swiper-button-next:hover::after,
.swiper-button-prev:hover::after {
  background-color: #1BB6F7;
}

/* 
.slider__image-fit {
  width: 100%;
  height: 600px;
  object-fit: cover;
} */

/* .carousel-control-prev,
.carousel-control-next {
  width: 3rem !important;
  margin: 1rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  border-radius: 50%;
  background-color: white;
  color: black;
  width: 3rem !important;
  height: 3rem !important;
  transition: background-color 0.5s ease;
  padding-top: 3px;
}

.carousel-control-prev-icon {
  margin-right: auto;
}

.carousel-control-next-icon {
  margin-left: auto;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
    background-color: #1BB6F7;
} */